import React, { useState } from "react";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";
import { CheckBox } from "components";
import { Flex, Text } from "primitives";

interface MultiCheckBoxProps {
  onChange: (params: any) => void;
  formData: any;
  schema: any;
  showSelectAll?: boolean;
}

const MultiCheckBox = (props: MultiCheckBoxProps) => {
  const { schema, formData, onChange, showSelectAll } = props;
  const [selectedValues, setSelectedValues] = useState(formData);

  let values: string[] = [];
  let labels: string[] = [];
  if (schema && schema.items && schema.items.enum) {
    values = schema.items.enum;
    labels = schema.items.enumNames || [];
  }

  const toggleCheckBox = (value: string) => {
    const index = selectedValues?.indexOf(value);
    if (index === -1) {
      selectedValues.push(value);
      setSelectedValues([...selectedValues]);
      return selectedValues;
    } else {
      selectedValues?.splice(index, 1);
      Array.isArray(selectedValues) && setSelectedValues([...selectedValues]);
      return selectedValues;
    }
  };

  const handleSelectAll = () => {
    if (selectedValues.length === values.length) {
      setSelectedValues([]);
      return [];
    }
    setSelectedValues(values);
    return values;
  };

  return (
    <Flex flexDirection="column">
      {showSelectAll && (
        <Flex justifyContent={"flex-start"} alignItems={"center"} mb={2}>
          <Text color="palette.white.0">Select all</Text>
          <CheckBox
            onChange={() => onChange(handleSelectAll())}
            checked={values.some((v) => selectedValues.includes(v))}
            indeterminate={
              selectedValues.length !== 0 &&
              selectedValues.length < values.length
            }
          />
        </Flex>
      )}
      {values.map((value: string, index: number) => {
        return (
          <CheckBox
            key={value}
            checked={selectedValues?.indexOf(value) !== -1}
            label={labels[index] || value}
            onChange={() => onChange(toggleCheckBox(value))}
            labelWidth={"auto"}
          />
        );
      })}
    </Flex>
  );
};

MultiCheckBox.displayName = "MultiCheckBox";

MultiCheckBox.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: MultiCheckBox.displayName
};

export default MultiCheckBox;
