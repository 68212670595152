import { DataSource } from "app/dataSource/models";
import { RouteComponentProps } from "react-router-dom";

export interface AlertCounterProps extends RouteComponentProps {
  getAlertcount: (satelliteId: number) => Promise<void>;
  alertCounter: {
    numCritical: number;
    numWarnings: number;
    numOthers: number;
  };
  satellites: [
    {
      id: number;
      label: string;
    }
  ];
  refreshData: boolean;
}

export interface AlertCounterState {
  alertColor: string;
  alertNumber: number | string;
}

/**
 * Alert Response and DTO
 */

//Response
export interface AlertResponse {
  id: string;
  satelliteId: number;
  dataSourceId: number;
  dataSourceName: string;
  receiveTimestamp: string;
  sendTimestamp: string;
  bootCount: number;
  onboardTimeMillis: number;
  valueStatus:
    | SimpleAlertValueStatusResponse
    | ArrayAlertValueStatusResponse
    | DynamicAlertValueStatusResponse;
  value: number | number[];
  dismissed: boolean;
}

export interface SimpleAlertValueStatusResponse {
  alertType: AlertType.SimpleAlert;
  triggeredParameters: [
    AlertValueResponse | OutOfBoundsResponse | NormalValueResponse
  ];
}

export interface DynamicAlertValueStatusResponse {
  alertType: AlertType.DynamicAlert;
  // TODO: finalize with BE
  triggeredParameters: [];
}

export enum DynamicAlertValue {}

export interface ArrayAlertValueStatusResponse {
  alertType: AlertType.ArrayAlert;
  triggeredParameters: [
    AlertValueResponse | OutOfBoundsResponse | NormalValueResponse
  ];
}

export interface OutOfBoundsResponse {
  type: AlertValueStatus.OutOfBoundsValue;
  index: number | null;
  valueBoundary: ValueBoundaryResponse;
  alertLevel?: AlertLevelResponse;
}

export interface AlertValueResponse {
  type: AlertValueStatus.AlertValue;
  index: number | null;
  alertLevel: AlertLevelResponse;
  valueBoundary?: ValueBoundaryResponse;
}

export interface NormalValueResponse {
  type: AlertValueStatus.NormalValue;
  index: number | null;
  alertLevel?: AlertLevelResponse;
  valueBoundary?: ValueBoundaryResponse;
}

export interface AlertLevelResponse {
  severity: Severity;
  upperThreshold: number;
  lowerThreshold: number;
}

export interface ValueBoundaryResponse {
  maxValue: number;
  minValue: number;
}

//DTO

export interface AlertDTO {
  id: string;
  satelliteId: number;
  dataSourceId: number;
  dataSourceName: string;
  receiveTimestamp: string;
  sendTimestamp: string;
  bootCount: number;
  onboardTimeMillis: number;
  value: AlertValue[];
  currentValue: number | number[];
  dismissed: boolean;
  type?: string;
  dynamicAlertsDetails?: any[]; //TODO: finalize types with BE
  notificationPolicy?: any[];
}

export interface AlertValue {
  index: number | null;
  alert: AlertDetails;
}

export interface AlertDetails {
  index: number | null;
  type: AlertValueStatus;
  severity?: Severity;
  upperThreshold?: number;
  lowerThreshold?: number;
  maxValue?: number;
  minValue?: number;
}

export interface GetAlertsResponse {
  data: AlertResponse[] | AlertDTO[] | null;
  total: number;
  nextPageUrl: string | null;
  previousPageUrl: string | null;
}

export interface GetAlertsDataSourceListResponse {
  data: AlertsDataSourceListResponse[];
  total: number;
  nextPageUrl: string | null;
  previousPageUrl: string | null;
}

export interface AlertsDataSourceListResponse {
  alert: AlertDTO;
  datasource: DataSource;
}

/*******/

export interface AlertState {
  loading: boolean;
  error: { msg: string; status: boolean };
  alerts: AlertDTO[];
  alertDefinitions: any[];
}

export interface SelectedAlert {
  id: string;
  dataSourceId: number;
  receiveTimestamp: string;
}

export enum AlertType {
  SimpleAlert = "SimpleAlert",
  ArrayAlert = "ArrayAlert",
  NormalValue = "NormalValue",
  DynamicAlert = "DynamicAlert"
}

export enum AlertValueStatus {
  AlertValue = "AlertValue",
  OutOfBoundsValue = "OutOfBoundsValue",
  NormalValue = "NormalValue",
  DynamicAlertValue = "DynamicAlertValue"
}

export enum Severity {
  Normal = "Normal",
  Warning = "Warning",
  Critical = "Critical"
}

export enum Colors {
  Default = "text.default",
  Normal = "text.success",
  Warning = "text.warning",
  Critical = "text.danger",
  OutOfBounds = "palette.purple.1",
  Black = "palette.black.0"
}

export interface TreeData {
  id: string;
  title: string;
  expanded: boolean;
  children: any;
  contentExpanded: boolean;
  header: boolean;
}

export interface AlertDefinition {
  alert: any;
  dataSourceId: number;
}

export interface AlertDefinitionDTO {
  id: number;
  dataSourceId: number;
  datasourceName?: string;
  active: boolean;
  alert: Alert;
}

export interface Alert {
  indexes?: Index[];
  alertType: string;
  alertConfigs?: AlertConfig[];
  metadata?: Metadata;
  dataSourceId?: number;
  id?: number;
}

export interface AlertConfig {
  condition: string;
  name: string;
  severity: string;
  instantReference: string;
  notificationPolicy: string;
  indexes?: number[];
}

export interface Index {
  index: number;
  valueBoundary: ValueBoundary;
  alertLevels: AlertLevel[];
}

export interface AlertLevel {
  severity: string;
  upperThreshold: number;
  lowerThreshold: number;
}

export interface ValueBoundary {
  maxValue: number;
  minValue: number;
}

export interface Metadata {
  createdOn: string;
  updatedOn: string;
  ownerId: null;
}

export interface IIconsMap {
  [key: string]: string;
}

export type CreateAlertDefinitionCommand = Partial<Alert>;

export type EditAlertDefinitionCommand = Partial<Alert>;

export const IconsMap: IIconsMap = {
  notifyOnTeams: "Teams",
  notifyByEmail: "Email",
  playSoundOnFE: "Bell"
};

export type Options = {
  page: number;
  pageSize: number;
};

export type AlertResponseWithPagination = {
  data?: AlertDefinitionDTO[];
  total?: number;
  nextPageUrl?: string;
  previousPageUrl?: string;
};

export interface SchemaErrors {
  [key: string]: any;
}

// Refine this as BE adds additional details on dynamic alerts
export interface IAlertDetails {
  type?: string;
  details?: any;
  colorsByValue: any;
}

export interface ISimpleAndArrayAlertDetails {
  details?: any;
  colorsByValue: string[];
}

export interface IDynamicAlertDetails {
  details?: any;
}

export type AlertConfigDetails = {
  alertType?: AlertType;
  alertLevels?: AlertLevelResponse[];
  valueBoundary?: ValueBoundaryResponse;
} & {
  indexes: AlertConfigDetails[];
};
